.p-button.p-button-tertiary, .p-buttonset.p-button-tertiary > .p-button, .p-splitbutton.p-button-tertiary > .p-button {
  border: solid 1px  $successButtonBg;
  background: $panelContentBg;
  color: $panelContentTextColor;

  &:enabled:hover {
    background: $successButtonHoverBg;
    color: $successButtonTextHoverColor;
    border-color: $successButtonHoverBorderColor;
  }

  &:enabled:focus {
    box-shadow: $successButtonFocusShadow;
  }

  &:enabled:active {
    background: $successButtonActiveBg;
    color: $successButtonTextActiveColor;
    border-color: $successButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: $successButtonBg;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      background: rgba($successButtonBg, $textButtonHoverBgOpacity);
      color: $successButtonBg;
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      background: rgba($successButtonBg, $textButtonActiveBgOpacity);
      color: $successButtonBg;
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: $successButtonBg;
    border-color: transparent;

    &:enabled:hover {
      background: rgba($successButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
      color: $successButtonBg;
    }

    &:enabled:active {
      background: rgba($successButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
      color: $successButtonBg;
    }
  }
}
.column-filter-overlay {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;
}
.column-filter-overlay .column-filter-row-items .column-filter-row-item {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.column-filter-row .column-filter-element {
  flex: 1 1 auto;
  width: 1%;
}
.column-filter-row .column-filter-menu-button, .column-filter-row .column-filter-clear-button {
  margin-left: 0.5rem;
}

.column-filter-clear-button, .column-filter-menu-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
.column-filter-overlay .column-filter-row-items .column-filter-row-item.p-highlight {
  color: var(--essdocs-highlight);
  background: var(--essdocs-highlight-text);
  font-weight: bold;
}
.p-filter-column {
  padding: 0.5rem 1rem !important;
}

.p-tabmenu-nav {
  overflow: scroll;
  overflow-y: hidden;
}
.p-tabmenu {
  .p-tabmenu-nav {
    border: unset;
  }
}
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon { 
  color: var(--essdocs-highlight) !important;
}
.p-datatable .p-datatable-tbody > tr.p-highlight > td .p-row-toggler {
  color: #ffffff !important;
}
.p-link, .p-button {
  &:focus {
    outline: unset !important;
    outline-offset: unset !important;
    box-shadow: unset !important;
  }
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
  padding: 10px;
}

.p-disabled, .p-component:disabled {
  opacity: 0.5;
}

.p-field-checkbox, .p-field-radiobutton {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

.p-column-filter-row {
  .p-column-filter-menu-button,
  .p-column-filter-clear-button {
    margin-left: $inlineSpacing;
  }
}

.p-column-filter-menu-button {
  width: $actionIconWidth;
  height: $actionIconHeight;
  color: $actionIconColor;
  border: $actionIconBorder;
  background: $actionIconBg;
  border-radius: $actionIconBorderRadius;
  transition: $actionIconTransition;

  &:hover {
    color: $actionIconHoverColor;
    border-color: $actionIconHoverBorderColor;
    background: $actionIconHoverBg;
  }

  &.p-column-filter-menu-button-open,
  &.p-column-filter-menu-button-open:hover {
    background: $actionIconHoverBg;
    color: $actionIconHoverColor;
  }

  &.p-column-filter-menu-button-active,
  &.p-column-filter-menu-button-active:hover {
    background: $highlightBg;
    color: $highlightTextColor;
  }

  &:focus-visible {
    @include focused();
  }

}

.p-column-filter-clear-button {
  width: $actionIconWidth;
  height: $actionIconHeight;
  color: $actionIconColor;
  border: $actionIconBorder;
  background: $actionIconBg;
  border-radius: $actionIconBorderRadius;
  transition: $actionIconTransition;

  &:hover {
    color: $actionIconHoverColor;
    border-color: $actionIconHoverBorderColor;
    background: $actionIconHoverBg;
  }

  &:focus-visible {
    @include focused();
  }
}

.p-column-filter-overlay {
  background: $inputOverlayBg;
  color: $inputListTextColor;
  border: $inputOverlayBorder;
  border-radius: $borderRadius;
  box-shadow: $inputOverlayShadow;
  min-width: $menuWidth;

  .p-column-filter-row-items {
    padding: $inputListPadding;

    .p-column-filter-row-item {
      margin: $inputListItemMargin;
      padding: $inputListItemPadding;
      border: $inputListItemBorder;
      color: $inputListItemTextColor;
      background: $inputListItemBg;
      transition: $listItemTransition;
      border-radius: $inputListItemBorderRadius;

      &.p-highlight {
        color: $highlightTextColor;
        background: $highlightBg;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        color: $inputListItemTextHoverColor;
        background: $inputListItemHoverBg;
      }

      &:focus-visible {
        @include focused-listitem();
      }
    }

    .p-column-filter-separator {
      border-top: $divider;
      margin: $menuSeparatorMargin;
    }
  }
}

.p-column-header-content {
  display: block;
}
.p-column-filter-overlay-menu {
  .p-column-filter-operator {
    padding: $inputListHeaderPadding;
    border-bottom: $inputListHeaderBorder;
    color: $inputListHeaderTextColor;
    background: $inputOverlayHeaderBg;
    margin: $inputListHeaderMargin;
    border-top-right-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
  }

  .p-column-filter-constraint {
    padding: $panelContentPadding;
    border-bottom: $divider;

    .p-column-filter-matchmode-dropdown {
      margin-bottom: $inlineSpacing;
    }

    .p-column-filter-remove-button {
      margin-top: $inlineSpacing;
    }

    &:last-child {
      border-bottom: 0 none;
    }
  }

  .p-column-filter-add-rule {
    padding: $panelFooterPadding;
  }

  .p-column-filter-buttonbar {
    padding: $panelContentPadding;
  }
}