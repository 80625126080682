.BargeInfo {
    padding: 10px;
}

.BargeInfoTitle {
    color: dimgrey;
    font-size: 14pt;
}

.BargeInfoLabel {
    color: dimgrey;
    font-size: 10pt;
}

.BargeInfoValue {
    color: black;
    font-weight: bold;
    font-size: 10pt;
}

.BargeDetails {
    padding: 10px;
}

.BargeDetailsFieldGroup {
    margin-bottom: 10px;
}

