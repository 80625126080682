.FileDragDropButton {
}

.FileDragDropButton .p-button {
    width: 200px;
}

.FileDragDropButton .p-button-icon-left {
    display: none;
}

.FileDragDropButton .p-button.p-button-text-icon-left .p-button-text {
    padding-left: 1em;
}

