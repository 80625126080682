.InboxBodyContainer {
    overflow-y: hidden;
    top: 55px;

    .p-datatable-table > .p-datatable-thead>tr>th, .p-datatable-resizable-table>.p-datatable-tfoot>tr>td, .p-datatable-resizable-table>.p-datatable-tbody>tr>td {
        white-space: normal;
    }
    .p-datatable-wrapper {
        min-height: 720px;
        max-height: 720px;
    }

}

.InboxNoBargesFoundMessage {
    text-align: center;
    padding: 10px;
    border: solid 1px #ccc;
}

.EssDocsPageTitleContainer {
    margin-top: 0px;
}
