.UserRolesCheckbox {
    margin-bottom: 5px;
}

.UserRolesLabel {
    font-weight: bold;
}

.checkbox-label {
    padding-left: 5px;
}

