.MessagesComponent {
    background-color: inherit;
}

.MessagesComponentMessageList {
    padding: 3px;
}

.MessagePanel {
    border: solid 1px #eaeaea;
    margin-bottom: 10px;
    border-radius: 3px;
}

.MessagesCheckboxLabel {
    margin-left: 3px;
    margin-right: 10px;
}

.MessageTypeNote {
    background-color: beige;
}

.MessageTypeActivity {
    background-color: #9fc2e4;
}

.MessageTypeReject {
    background-color: #91b4d6;
}

.MessagePanel {
}

.MessagesComponentToolbar {
    padding: 5px;
}

.MessagesComponentToolbar .p-button-text {
    font-size: 10px;
}

.MessagePanelHeader {
    border-radius: 3px 3px 0 0 ;
    background-color: rgba(0, 0, 0, .2);
    padding-top: 6px;
    padding-left: 5px;
    height: 35px;
    vertical-align: center;
}

.MessagePanelHeaderCompany {
    font-size: 7pt;
    font-weight: bold;
    margin-bottom: 3px;
    display: grid;
}

.MessagePanelHeaderTimestamp {
    font-size: 7pt;
    font-style: italic;
    text-align: right;
    margin-right: 5px;
}

.MessagePanelMessageContent {
    padding: 5px;
    font-size: 12px;
}
