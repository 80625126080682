.TradeString {
    overflow: hidden;
    padding: 5px;
}

.TradeString table {
    width: 100%;
    font-size: 11px;
}

.TradeString table th {
    color: dimgrey;
    text-align: left;
}

.TradeString table td,  .TradeString table tr {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}


.TradeString table td i {
    color: green;
    font-size: 13px;
}

.TradeStringSeparator {
    border-left: solid 2px green;
    margin-left: 4px;
}

.TradeStringValue {
    font-weight: bold;
}

.TradeStringOwner {
    font-weight: bold;
}

.TradeStringInfo {
    font-size: 9px;
    color: #888;
    vertical-align: top;
}




