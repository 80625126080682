
.LogDisplayer tr {
    background-color: #eaeaea;
}

.LogDisplayer td {
    padding: 2px;
}

.LogDisplayer tr:nth-child(odd) {
    background-color: #ccc;
}


.LogDisplayer table {
    width: 100%;
    font-size: 12px;
    margin-top: 10px;
}
