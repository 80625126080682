.DocumentList {
    overflow: hidden;
    cursor: pointer;
}

.DocumentRow {
    background-color: #efefef;
    border-top: solid 1px #ccc;
    padding: 5px;
    margin: 0;
}

.DocumentRowBargeDetails {
    background-color: #4C6A8A;
    color: white;
    border-top: solid 1px #ccc;
    padding: 5px;
}

.DocumentRowBargeDetails:hover, .DocumentBargeRowSelected:hover {
    background-color: #304460;
}

.DocumentRowDocument:hover {
    background-color: #c3c3c3;
}

.DocumentRowSelected {
    background-color: #dcdcdc;
    color: rgb(240, 120, 40);
}

.DocumentListName {
    display: inline-block;
}

.DocumentListButton {
    display: inline-block;
    text-align: center;
    font-size: 16px;
}

.DocumentListButton:hover {
    color: blue;
}

.DocumentIcon {
    font-size: 14px;
    width: 20px;
}
