.BargeInfoBargeId {
    font-size: 14pt;
}

.BargeDetailsPanel {
    background-color: #f0f0f0;
    height: 100%;
    /*border: solid 1px #ccc;*/
    margin: 3px;
    /*border-radius: 5px;*/
    /*box-shadow: 0 0 2px rgba(0, 0, 0, .2);*/
}

.BargeDetailsPanelTitle {
    color: dimgrey;
    font-weight: bold;
    font-size: 12pt;
    padding: 10px;
}

.BargeInfoWaitSpinner {
    color: black;
    font-size: 16px;
    margin-right: 10px;
}
