.App {
}

:root {
    --essdocs-primary: #4C6A8A;
    --essdocs-text: #ffffff;
    --essdocs-highlight: rgb(240,120,40);
    --essdocs-highlight-text: #ffffff;


    --amplify-font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif';
    --amplify-text-xxs: 0.75rem;
    --amplify-text-xs: 0.81rem;
    --amplify-text-sm: 0.875rem;
    --amplify-text-md: 1rem;
    --amplify-text-lg: 1.5rem;
    --amplify-text-xl: 2rem;
    --amplify-text-xxl: 2.5rem;
    --amplify-primary-color: #4C6A8A;
    --amplify-primary-contrast: var(--amplify-white);
    --amplify-primary-tint: #4C6A8A;
    --amplify-primary-shade: #4C6A8A;
    --amplify-secondary-color: #152939;
    --amplify-secondary-contrast: var(--amplify-white);
    --amplify-secondary-tint: #31465f;
    --amplify-secondary-shade: #1F2A37;
    --amplify-tertiary-color: #5d8aff;
    --amplify-tertiary-contrast: var(--amplify-white);
    --amplify-tertiary-tint: #7da1ff;
    --amplify-tertiary-shade: #537BE5;
    --amplify-grey: #828282;
    --amplify-light-grey: #c4c4c4;
    --amplify-white: #ffffff;
    --amplify-red: #dd3f5b;

    .amplify-tabs {
        display: none;
    }

    [data-amplify-authenticator][data-variation=modal] {
        background-color: var(--amplify-white);
    }
}

.AppMain {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    .NavigatorLogoTitle {
        font-size: 14pt;
        margin-bottom: 0px;
    }

    #NavigatorExpandCollapseButton {
        color: var(--essdocs-text);
    }
}

.AppBody {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin-left: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 10px;
    transition: margin-left .2s;
    background-color: rgba(240, 240, 240, .2);
}

.DialogLabel {
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 3px;
}

body div {
    text-align: left;
}

.DialogValue {
    font-weight: bold;
}
